<template>
  <div class="login-page">
    <!-- 背景 -->
    <div class="background"></div>

    <!-- 登录表单 -->
    <div class="login-container">
      <h2 class="login-title">欢迎光临我们的相册</h2>
      <el-form :model="form" class="login-form" @submit.native.prevent="handleLogin">
        <el-form-item>
          <el-input
            v-model="form.name"
            placeholder="用户名"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            ref="passwordInput"
            v-model="form.password"
            placeholder="密码"
            prefix-icon="el-icon-lock"
            show-password
            type="password"
            @keyup.enter="handleLogin"  
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" block @click="handleLogin">登录</el-button>
        </el-form-item>
      </el-form>

    </div>

    <!-- Footer -->
    <footer class="footer">
      <div class="footer-content">
        <a href="http://beian.miit.gov.cn/" target="_blank" class="beian-link">
          <div>蜀ICP备2024113083号</div>
        </a>
        <div class="copyright">
          Copyright © 2024 Like_GirlV1.0 Web. All Rights Reserved.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import usersApi from "@/api/usersApi"; // 引入封装的用户 API
export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        name: "zhouxiaoli",
        password: "",
      },
    };
  },
  mounted() {
    // 页面加载后自动聚焦密码输入框
    this.$refs.passwordInput.focus();
  },
  methods: {
    async handleLogin() {
      if (!this.form.name || !this.form.password) {
        this.$message.error("请输入完整的用户名和密码！");
        return;
      }

      try {
        // 调用后端登录接口
        const response = await usersApi.login({
          name: this.form.name,
          password: this.form.password,
        });

        // 检查状态码是否为 200 且返回的用户数据是否存在
        if (response.code === 200 && response.data) {
          this.$message.success("登录成功！");
          // 存储用户信息（如 token 或用户 ID）
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("jwtToken", response.data.auth);
          // 登录成功后跳转到 File 页面
          this.$router.push("/");
        } else {
          this.$message.error(response.message || "登录失败，用户信息不存在！");
        }
      } catch (error) {
        console.error("登录失败：", error);
        this.$message.error("登录失败，请稍后再试！");
      }
    },
  },
};
</script>

<style scoped>
/* 背景 */
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("@/assets/images/login2.jpg") no-repeat center center fixed;
  background-size: cover;
  z-index: -1;
  filter: brightness(0.8);
}

/* 页面整体样式 */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* padding: 20px; */
  color: #fff;
  font-family: "Arial", sans-serif;
  box-sizing: border-box;
  border: 1px solid black;
}

/* 登录容器 */
.login-container {
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 350px;
  text-align: center;
  box-sizing: border-box;
}

/* 登录标题 */
.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

/* 登录表单样式 */
.login-form {
  width: 100%;
}

.el-input {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.el-input .el-input__inner {
  background: transparent;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
}

.el-input .el-input__inner:focus {
  border-bottom: 1px solid #409eff;
}

.el-button {
  border-radius: 5px;
}

.el-button--primary {
  background-color: #409eff;
  border: none;
}

/* 忘记密码链接 */
.forgot-password {
  margin-top: 10px;
}

.forgot-password a {
  color: #409eff;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password a:hover {
  text-decoration: underline;
}

/* Footer 样式 */
.footer {
  background-color: #222; /* 深色背景 */
  color: #fff; /* 文字颜色 */
  padding: 20px 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  z-index: 10; /* 确保在最上层 */
}

.footer-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.beian-link {
  color: #409eff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 5px;
}

.beian-link:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 12px;
  color: #aaa; /* 浅灰色 */
}

/* 响应式设计 */
@media (max-width: 768px) {
  .footer {
    padding: 15px 0;
  }
  .beian-link {
    font-size: 12px;
  }
  .copyright {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 10px 0;
  }
  .beian-link {
    font-size: 10px;
  }
  .copyright {
    font-size: 9px;
  }
}
</style>
