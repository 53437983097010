<template>
  <div ref="snowContainer" class="snow-container"></div>
</template>

<script>
import * as THREE from "three";

export default {
  props: {
    snowCount: {
      type: Number,
      default: 1000, // 雪花数量
    },
    speed: {
      type: Number,
      default: 0.02, // 雪花下落速度
    },
    size: {
      type: Number,
      default: 1, // 雪花大小
    },
    color: {
      type: String,
      default: "#ffffff", // 雪花颜色
    },
    windSpeed: {
      type: Number,
      default: 0.01, // 水平方向风速
    },
  },
  mounted() {
    this.initSnowEffect();
  },
  methods: {
    initSnowEffect() {
      const container = this.$refs.snowContainer;

      // 初始化场景、相机和渲染器
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 20;

      const renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 0); // 透明背景
      container.appendChild(renderer.domElement);

      // 创建雪花粒子几何体
      const snowGeometry = new THREE.BufferGeometry();
      const positions = [];
      const velocities = []; // 存储每个雪花的速度

      for (let i = 0; i < this.snowCount; i++) {
        const x = Math.random() * 40 - 20; // 雪花的 x 坐标
        const y = Math.random() * 40 - 20; // 雪花的 y 坐标
        const z = Math.random() * 40 - 20; // 雪花的 z 坐标

        positions.push(x, y, z); // 雪花位置
        velocities.push(Math.random() * this.speed + 0.01); // 下落速度
      }

      snowGeometry.setAttribute("position", new THREE.Float32BufferAttribute(positions, 3));
      snowGeometry.setAttribute("velocity", new THREE.Float32BufferAttribute(velocities, 1));

      // 雪花材质
      const snowMaterial = new THREE.PointsMaterial({
        color: new THREE.Color(this.color),
        size: this.size,
        transparent: true,
        opacity: 0.8,
      });

      // 创建雪花粒子系统
      const snow = new THREE.Points(snowGeometry, snowMaterial);
      scene.add(snow);

      // 动画
      const animate = () => {
        requestAnimationFrame(animate);

        const positions = snowGeometry.attributes.position.array;
        const velocities = snowGeometry.attributes.velocity.array;

        for (let i = 0; i < positions.length; i += 3) {
          // 下落效果
          positions[i + 1] -= velocities[i / 3];

          // 水平方向风吹效果
          positions[i] += Math.sin(Date.now() * 0.001) * this.windSpeed;

          // 如果雪花超出屏幕范围，重置到顶部
          if (positions[i + 1] < -20) {
            positions[i] = Math.random() * 40 - 20; // 重置 x 坐标
            positions[i + 1] = 20; // 重置 y 坐标
            positions[i + 2] = Math.random() * 40 - 20; // 重置 z 坐标
          }
        }

        snowGeometry.attributes.position.needsUpdate = true; // 通知 Three.js 更新几何体

        renderer.render(scene, camera);
      };

      animate();

      // 窗口大小调整
      window.addEventListener("resize", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
    },
  },
};
</script>

<style scoped>
.snow-container {
  position: fixed; /* 固定在页面顶部 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* 确保在页面顶层 */
  pointer-events: none; /* 不拦截鼠标事件 */
  background: transparent; /* 保持透明 */
}
</style>
