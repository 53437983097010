import apiClient from "./api";

const photosApi = {
  /**
   * 分页查询照片
   * @param {Object} params 查询参数
   * @returns {Promise}
   */
  getPhotos(params) {
    return apiClient.get("/photos", { params });
  },

  /**
   * 根据 ID 查询照片
   * @param {Number} id 照片 ID
   * @returns {Promise}
   */
  getPhotoById(id) {
    return apiClient.get(`/photos/${id}`);
  },

  /**
   * 新增照片
   * @param {Object} data 照片数据
   * @returns {Promise}
   */
  addPhoto(data) {
    return apiClient.post("/photos", data);
  },

  /**
   * 编辑照片
   * @param {Object} data 照片数据
   * @returns {Promise}
   */
  editPhoto(data) {
    return apiClient.put("/photos", data);
  },

  /**
   * 删除照片
   * @param {Number} id 照片 ID
   * @returns {Promise}
   */
  deleteById({ photoId, albumId }) {
    return apiClient.delete("/photos", {
      headers: { 'Content-Type': 'application/json' }, // 确保设置 Content-Type 为 application/json
      data: { photoId, albumId }  // 将请求体数据传递过去
    });
  },
  

  /**
   * 上传图片
   * @param {File} file 图片文件
   * @returns {Promise}
   */
  uploadPhoto(file) {
    const formData = new FormData();
    formData.append("file", file);

    return apiClient.post("/photos/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  /**
   * 批量上传图片
   * @param {Number} albumId 相册 ID
   * @param {File[]} files 图片文件列表
   * @returns {Promise}
   */
  uploadPhotosBatch(formData) {
    return apiClient.post("/photos/uploadBatch", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  /**
   * 单文件上传
   * @param {Number} albumId 相册 ID
   * @param {File} file 图片文件
   * @returns {Promise}
   */
  uploadPhotoSingle(albumId, file) {
    const formData = new FormData();
    formData.append("albumId", albumId);
    formData.append("file", file);

    return apiClient.post("/photos/uploadSingle", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default photosApi;
