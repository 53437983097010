<template>
  <div class="photo-detail-page">
    <!-- 顶部操作区域 -->
    <header class="header">
      <el-button
        type="text"
        icon="el-icon-arrow-left"
        @click="goBack"
        class="back-button"
      >
        返回
      </el-button>
      <h1 class="photo-title">{{ photo.title }}</h1>
      <div class="action-buttons">
        <el-button type="text" icon="el-icon-share" @click="sharePhoto">分享</el-button>
        <el-button type="text" icon="el-icon-download" @click="downloadPhoto">下载</el-button>
      </div>
    </header>

    <!-- 照片展示区域 -->
    <div class="photo-display">
      <div class="photo-container">
        <img :src="photo.path" alt="photo" class="photo-image" />
      </div>
    </div>

    <!-- 照片信息 -->
    <div class="photo-info">
      <p><strong>拍摄时间：</strong>{{ photo.date }}</p>
      <p><strong>位置：</strong>{{ photo.location || '未提供' }}</p>
      <p><strong>标签：</strong>
        <span v-for="(tag, index) in photo.tags" :key="index" class="photo-tag">
          {{ tag }}
        </span>
      </p>
      <el-button
        type="success"
        icon="el-icon-like"
        @click="likePhoto"
        class="like-button"
      >
        点赞 ({{ photo.likes }})
      </el-button>
    </div>

    <!-- 评论区域 -->
    <div class="comments-section">
      <h2>评论</h2>
      <el-form :model="newComment" class="comment-form">
        <el-form-item>
          <el-input
            type="textarea"
            v-model="newComment.text"
            placeholder="写下你的评论..."
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitComment">提交评论</el-button>
        </el-form-item>
      </el-form>
      <div class="comments-list">
        <div v-for="(comment, index) in comments" :key="index" class="comment">
          <p><strong>{{ comment.user }}</strong>：{{ comment.text }}</p>
          <span class="comment-date">{{ comment.date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import photosApi from "@/api/photosApi"; // 引入封装的照片 API

export default {
  name: "PhotoDetailPage",
  props: ["photoId"], // 从路由接收 photoId
  data() {
    return {
      photo: {
        title: "",
        src: "",
        date: "",
        location: "",
        tags: [],
        likes: 0,
      },
      comments: [], // 照片评论列表
      newComment: {
        text: "",
      },
    };
  },
  created() {
    this.fetchPhotoDetails(); // 获取照片详情
    this.fetchComments(); // 获取评论数据
  },
  methods: {
    /**
     * 获取照片详情
     */
    async fetchPhotoDetails() {
      try {
        const response = await photosApi.getPhotoById(this.photoId);
        if (response.code === 200) {
          this.photo = response.data;
        } else {
          this.$message.error(response.message || "获取照片详情失败");
        }
      } catch (error) {
        console.error("获取照片详情失败：", error);
        this.$message.error("获取照片详情失败");
      }
    },

    /**
     * 获取照片评论
     */
    async fetchComments() {
      
    },

    /**
     * 返回上一页
     */
    goBack() {
      this.$router.go(-1); // 返回到上一个页面
    },

    /**
     * 分享照片
     */
    sharePhoto() {
      this.$message.success("分享链接已复制到剪贴板！");
    },

    /**
     * 下载照片
     */
    downloadPhoto() {
      const link = document.createElement("a");
      link.href = this.photo.src;
      link.download = `${this.photo.title}.jpg`;
      link.click();
      this.$message.success("照片下载成功！");
    },

    /**
     * 点赞照片
     */
    async likePhoto() {
      try {
        const response = await photosApi.likePhoto(this.photoId);
        if (response.code === 200) {
          this.photo.likes += 1;
          this.$message.success("感谢你的点赞！");
        } else {
          this.$message.error(response.message || "点赞失败");
        }
      } catch (error) {
        console.error("点赞失败：", error);
        this.$message.error("点赞失败");
      }
    },

    /**
     * 提交评论
     */
    async submitComment() {
      if (!this.newComment.text) {
        this.$message.error("评论内容不能为空！");
        return;
      }
      try {
        const response = await photosApi.addComment(this.photoId, {
          text: this.newComment.text,
        });
        if (response.code === 200) {
          this.comments.push({
            user: "游客", // 假设为匿名用户
            text: this.newComment.text,
            date: new Date().toISOString().split("T")[0],
          });
          this.newComment.text = "";
          this.$message.success("评论提交成功！");
        } else {
          this.$message.error(response.message || "提交评论失败");
        }
      } catch (error) {
        console.error("提交评论失败：", error);
        this.$message.error("提交评论失败");
      }
    },
  },
};
</script>

<style scoped>
.photo-detail-page {
  padding: 20px;
  font-family: "Arial", sans-serif;
  background: linear-gradient(180deg, #ffe4e1, #fffaf0);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* 顶部操作区域 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #ff9aa2, #ffb6c1);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(255, 105, 180, 0.3);
}

.back-button {
  color: white;
  font-size: 14px;
  transition: color 0.3s ease;
}

.back-button:hover {
  color: #ffe4e1;
}

.photo-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 0 2px 4px rgba(255, 182, 193, 0.5);
}

.action-buttons .el-button {
  color: white;
  font-size: 14px;
  transition: color 0.3s ease;
}

.action-buttons .el-button:hover {
  color: #ffe4e1;
}

/* 照片展示区域 */
.photo-display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
}

.photo-container {
  max-width: 100%;
  max-height: 500px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(255, 182, 193, 0.4);
}

.photo-image {
  width: 100%;
  height: auto;
  object-fit: contain; /* 保证图片显示完整 */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photo-image:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(255, 105, 180, 0.5);
}

/* 照片信息 */
.photo-info {
  background: linear-gradient(135deg, #fff0f5, #ffe4e1);
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(255, 182, 193, 0.3);
  width: 100%;
  max-width: 800px;
}

.photo-info p {
  font-size: 14px;
  margin: 10px 0;
  color: #555;
}

.photo-tag {
  display: inline-block;
  margin: 5px 5px 0 0;
  padding: 4px 12px;
  background: #ff6f91;
  color: white;
  border-radius: 15px;
  font-size: 12px;
  transition: background 0.3s ease;
}

.photo-tag:hover {
  background: #ff477e;
}

.like-button {
  margin-top: 10px;
  background: #ff6f91;
  border-radius: 20px;
  color: white;
  transition: background 0.3s ease, transform 0.3s ease;
}

.like-button:hover {
  background: #ff477e;
  transform: scale(1.1);
}

/* 评论区域 */
.comments-section {
  background: linear-gradient(135deg, #fff0f5, #ffe4e1);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(255, 182, 193, 0.3);
  width: 100%;
  max-width: 800px;
}

.comments-section h2 {
  font-size: 20px;
  font-weight: bold;
  color: #ff69b4;
  margin-bottom: 20px;
}

.comment-form {
  margin-bottom: 20px;
}

.comment-form .el-input {
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(255, 105, 180, 0.2);
}

.comment-form .el-button {
  border-radius: 20px;
  background: #ff6f91;
  color: white;
  font-weight: bold;
  transition: background 0.3s ease;
}

.comment-form .el-button:hover {
  background: #ff477e;
}

.comments-list {
  max-height: 300px;
  overflow-y: auto;
}

.comment {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffe4e1;
}

.comment p {
  font-size: 14px;
  color: #555;
}

.comment-date {
  font-size: 12px;
  color: #888;
}
</style>

