import apiClient from "./api";

const loveChecklistApi = {
  /**
   * 分页查询恋爱清单
   * @param {Object} params 查询参数
   * @returns {Promise}
   */
  queryByPage(params) {
    return apiClient.get("/loveChecklist", { params });
  },

  /**
   * 根据 ID 查询恋爱清单
   * @param {Number} id 清单 ID
   * @returns {Promise}
   */
  queryById(id) {
    return apiClient.get(`/loveChecklist/${id}`);
  },

  /**
   * 新增恋爱清单
   * @param {Object} data 清单数据
   * @returns {Promise}
   */
  addChecklist(data) {
    return apiClient.post("/loveChecklist", data);
  },

  /**
   * 编辑恋爱清单
   * @param {Object} data 清单数据
   * @returns {Promise}
   */
  editChecklist(data) {
    return apiClient.put("/loveChecklist", data);
  },

  /**
   * 删除恋爱清单
   * @param {Number} id 清单 ID
   * @returns {Promise}
   */
  deleteById(id) {
    return apiClient.delete("/loveChecklist", { params: { id } });
  },
};

export default loveChecklistApi;
