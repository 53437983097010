<template>
  <div class="home-page">
    <RainEffect v-if="weatherType.includes('小雨')" :rainCount="700" :speed="0.6" :length="0.4" color="gray" />
    <RainEffect v-if="weatherType.includes('大雨')" :rainCount="1800" :speed="0.8" :length="0.7" color="gray" />  
    <SnowEffect v-if="weatherType.includes('雪')" :snowCount="800" :speed="0.05" :size="0.2" color="white" :windSpeed="0.02" />
    <!-- <SunEffect :snowCount="800" :speed="0.05" :size="0.2" color="white" :windSpeed="0.02" /> -->
    <!-- 顶部栏 -->
    <header class="header">
      <div class="left">恋爱日记</div>
      <div> {{weatherInfo}}</div>
      <div class="right">
        <div class="scroll-container">
          {{ weather ? weather.notice : "喜欢在 喜欢晚餐 喜欢你~" }}
        </div>
      </div>

    </header>

    <!-- 主体内容 -->
    <main class="main-section">
      <!-- 用户头像和爱心 -->
      <div class="profiles">
        <div class="profile">
          <img :src="user1.avatar" alt="用户1头像" class="avatar" />
          <div class="name">{{ user1.name }}</div>
        </div>
        <div class="heart">❤️</div>
        <div class="profile">
          <img :src="user2.avatar" alt="用户2头像" class="avatar" />
          <div class="name">{{ user2.name }}</div>
        </div>
      </div>

      <!-- 计时器 -->
      <div class="timer-section">
        <p>这是我们一起走过的</p>
        <h2 class="timer">{{ timer }}</h2>
      </div>

      <!-- 功能区域 -->
      <section class="features">
        <div
          class="feature-card"
          v-for="(feature, index) in features"
          :key="index"
          @click="navigateTo(feature)"
        >
          <img
            :src="feature.icon"
            alt="图标"
            class="feature-icon-image"
            v-if="feature.customIcon"
          />
          <el-icon
            :component="feature.icon"
            v-else
            class="feature-icon"
          />
          <h3 class="feature-title">{{ feature.title }}</h3>
          <p class="feature-desc">{{ feature.description }}</p>
        </div>
      </section>
    </main>

    <!-- 页脚 -->
    <footer class="footer">
      <a href="http://beian.miit.gov.cn/" target="_blank">
        <div>蜀ICP备2024113083号</div>
      </a>
      <div>Copyright © 2024 Like_GirlV1.0 Web. All Rights Reserved.</div>
    </footer>
  </div>
</template>

<script>
import weatherApi from "@/api/weatherApi";
import RainEffect from "./RainEffect.vue";
import SnowEffect from "./SnowEffect.vue";
import SunEffect from "./SunEffect.vue";
export default {
  name: "HomePage",
  components: {
    RainEffect,
    SnowEffect,
    SunEffect,
  },
  data() {
    return {
      user1: { name: "Li", avatar: require("@/assets/images/boy.png") },
      user2: { name: "Zhou", avatar: require("@/assets/images/baby.jpg") },
      features: [
        {
          icon: require("@/assets/images/lover.png"),
          title: "点点滴滴",
          description: "记录你我生活",
          customIcon: true,
          path: "/timeline",
        },
        {
          icon: require("@/assets/images/map.png"),
          title: "足迹",
          description: "我们踏遍的足迹",
          customIcon: true,
          path: "/map", // 跳转路径
        },
        {
          icon: require("@/assets/images/loveimg.png"),
          title: "恋爱相册",
          description: "恋爱相册 记录触碰时间",
          customIcon: true,
          path: "/file", // 跳转路径
        },
        {
          icon: require("@/assets/images/xinf.png"),
          title: "恋爱清单",
          description: "恋爱列表 你我之间的约定",
          customIcon: true,
          path: "/lovelist", // 跳转路径
        },
      ],
      timer: "",
      weather: null,
      weatherInfo: "",
      weatherType: "",
      location: "未获取位置信息",
    };
  },
  created() {
    this.startTimer();
    this.getLocation();
    // this.test();
  },
  methods: {
    test(){
      if(navigator.geolocation){  
        navigator.geolocation.getCurrentPosition(onSuccess , onError);  
      }else{  
        alert("您的浏览器不支持使用HTML 5来获取地理位置服务");  
      }  
      //定位数据获取成功响应  
      function  onSuccess(position){  
            alert('纬度: '          + position.coords.latitude          + '\n' +  
            '经度: '         + position.coords.longitude );
      }  
      //定位数据获取失败响应  
      function onError(error) {  
        switch(error.code)  
        {  
          case error.PERMISSION_DENIED:  
          alert("您拒绝对获取地理位置的请求");  
          break;  
          case error.POSITION_UNAVAILABLE:  
          alert("位置信息是不可用的");  
          break;  
          case error.TIMEOUT:  
          alert("请求您的地理位置超时");  
          break;  
          case error.UNKNOWN_ERROR:  
          alert("未知错误");  
          break;  
        }  
      }  
    },
    async getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.location = `纬度: ${latitude}, 经度: ${longitude}`;
            console.log("Location: ", this.location);

            // 调用后端接口获取 Zone Code
            weatherApi
              .getZoneCode(latitude, longitude)
              .then((response) => {
                this.zoneCode = response.data; // 保存返回的 Zone Code
                console.log("Zone Code: ", this.zoneCode);
                this.zoneCode = this.zoneCode && this.zoneCode != null ? this.zoneCode : 101050101;
                this.getWeather(this.zoneCode);
              })
              .catch((error) => {
                console.error("Error fetching zone code: ", error);
              });
          },
          (error) => {
            switch (error.code) {
              case 1:
                this.location = "用户拒绝提供位置信息。";
                break;
              case 2:
                this.location = "无法获取位置信息。";
                break;
              case 3:
                this.location = "获取位置信息超时。";
                break;
              default:
                this.location = "未知错误。";
            }
            alert("获取位置信息失败:" + this.location);
            this.getWeather(101270505);
          }
        );
      } else {
        this.location = "浏览器不支持 Geolocation API。";
        this.getWeather(101270505);
      }
    },
    async getWeather(zoneCode) {
      console.log('zoneCode',zoneCode);
      try {
        const response = await weatherApi.getWeather(zoneCode);
        // Check if response.data.data.forecast exists and is an array before accessing it
        if (response?.data?.data?.forecast && Array.isArray(response.data.data.forecast)) {
          this.weather = response.data.data.forecast[0];
          this.weatherInfo = 
            this.weather.low.replace('低温', '') + "~" + 
            this.weather.high.replace('高温', '') + "/" + 
            this.weather.type;
          this.weatherType = this.weather.type;
          console.log("this.weather", this.weather);
        } else {
          // Handle case where forecast data is missing or empty
          console.warn("No forecast data available");
          this.weather = [];
        }
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    },
    startTimer() {
      const startDate = new Date("2022-11-13T08:00:00");
      setInterval(() => {
        const now = new Date();
        const diff = now - startDate;

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        this.timer = `${days}天 ${hours}时 ${minutes}分 ${seconds}秒`;
      }, 1000);
    },
    navigateTo(feature) {
      if (feature.path) {
        this.$router.push(feature.path); // 路由跳转
      }
    },
  },
};
</script>


<style scoped>

/* 通用样式 */
.home-page {
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #ffc6cc;
  text-align: center;
  overflow-x: hidden;
  border: 5px solid #ffc6cc; /* 粉色边框 */
  width: 100%; /* 占满整个窗口宽度 */
  height: 100%; /* 占满整个窗口高度 */
  box-sizing: border-box; /* 包括边框在内计算宽高 */
}

/* 顶部栏 */
.header {
  /* position: fixed;  */
  top: 0; /* 距离页面顶部为 0 */
  left: 0; /* 距离页面左侧为 0 */
  width: 100%; /* 占满整个页面宽度 */
  z-index: 1000; /* 确保它在其他元素之上 */
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #ffe4e1;
  font-size: 14px;
  color: #666;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}


.header .left {
  font-weight: bold;
}

.header .right {
  font-style: italic;
  margin-right: 28px;
}

/* 主体内容 */
.main-section {
  background-color: #ffc6cc;
  padding: 40px 20px;
  background: url("@/assets/images/background.jpg") no-repeat center center fixed;
}

.profiles {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.profile {
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.name {
  font-size: 14px;
  font-weight: bold;
}

.heart {
  font-size: 30px;
  color: #ff6f91;
}

/* 计时器 */
.timer-section {
  margin-top: 20px;
  height: 115px;
}

.timer {
  font-size: 24px;
  color: #444;
  margin-top: 10px;
  font-weight: bold;
}

.timer {
  min-height: 40px; /* 根据需要调整最小高度 */
  line-height: 40px; /* 设置与最小高度相同的行高，保持居中 */
}

/* 功能区域 */
.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.feature-card {
  width: 150px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  font-size: 32px;
  color: #4caf50;
  margin-bottom: 10px;
}

.feature-icon-image {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.feature-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.feature-desc {
  font-size: 12px;
  color: #777;
}

/* 页脚 */
.footer {
  background-color: #ffe4e1;
  padding: 20px;
  font-size: 12px;
  color: #666;
}

/* 响应式样式 */
@media (max-width: 768px) {
  /* .profiles {
    flex-direction: column;
  } */

  .features {
    gap: 15px;
  }

  .feature-card {
    width: 120px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .avatar {
    width: 80px;
    height: 80px;
  }

  .feature-card {
    width: 100px;
    padding: 10px;
  }

  .feature-icon {
    font-size: 24px;
  }

  .feature-icon-image {
    width: 40px;
    height: 40px;
  }

  .feature-title {
    font-size: 14px;
  }

  .feature-desc {
    font-size: 10px;
  }
}
.heart {
  font-size: 40px; /* 增大心脏图标 */
  color: #ff6f91;
  animation: heartbeat 1.5s infinite; /* 添加心跳动画 */
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.timer-section {
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 15px; /* 圆角 */
  background: linear-gradient(135deg, #ff9a9e, #fad0c4, #fbc2eb); /* 渐变背景 */
  box-shadow: 0 5px 15px rgba(255, 105, 180, 0.2); /* 阴影 */
  position: relative; /* 为伪元素设置 */
  overflow: hidden; /* 防止伪元素溢出 */
}

.timer-section::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 10%, transparent 60%);
  animation: rotateBackground 6s linear infinite;
  z-index: 0;
}

.timer-section p {
  margin: 0;
  font-size: 16px;
  font-style: italic;
  color: #fff;
  z-index: 1; /* 保持文字在伪元素上层 */
  position: relative;
}

.timer {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  z-index: 1;
  position: relative;
  animation: glow 1.5s infinite;
}

/* 动画：背景旋转 */
@keyframes rotateBackground {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 动画：文字呼吸效果 */
@keyframes glow {
  0% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.4), 0 0 10px rgba(255, 255, 255, 0.3);
  }
  50% {
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.5);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.4), 0 0 10px rgba(255, 255, 255, 0.3);
  }
}
.right {
  overflow: hidden; /* 隐藏超出部分 */
  white-space: nowrap; /* 强制内容不换行 */
  position: relative; /* 相对定位 */
  width: 120px; /* 设置固定宽度，控制滚动区域大小 */
}

.scroll-container {
  display: inline-block; /* 内联块，支持滚动 */
  animation: scroll-horizontal 10s linear infinite; /* 滚动动画 */
  white-space: nowrap; /* 防止文字换行 */
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(100%); /* 从右侧开始 */
  }
  100% {
    transform: translateX(-100%); /* 滚动到左侧结束 */
  }
}

</style>
