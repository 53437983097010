<template>
  <div class="timeline-container">
    <div style="height: 100%;">
      <!-- 时间轴 -->
      <el-timeline :reverse="true" :direction="timelineDirection">
        <el-timeline-item
          v-for="(item, index) in timelineData"
          :key="index"
          :timestamp="formatTimestamp(item.timestamp)"
          :icon="item.icon"
          :color="item.color"
        >
          <div class="timeline-item-content">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
            <img v-if="item.image" :src="item.image" alt="Image" class="timeline-image" />
          </div>
        </el-timeline-item>
      </el-timeline>

      <!-- 添加事件图标 -->
      <el-tooltip class="item" effect="dark" content="添加事件" placement="top">
        <el-button
          type="primary"
          circle
          class="add-button"
          @click="openDialog"
        >
          <img src="@/assets/images/pen.png" alt="add event" class="add-icon" />
        </el-button>
      </el-tooltip>
    </div>

    <!-- 弹出对话框 -->
    <el-dialog
      title="添加事件"
      v-model="dialogVisible"
      @close="resetForm"
      custom-class="custom-dialog"
      width="88%"
    >
      <div v-loading="loading">
        <el-form :model="newEvent" label-width="100px">
          <el-form-item label="标题">
            <el-input v-model="newEvent.title" placeholder="请输入事件标题"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input
              type="textarea"
              v-model="newEvent.content"
              placeholder="请输入事件描述"
            ></el-input>
          </el-form-item>
          <!-- 图片选择 -->
          <el-form-item label="图片">
            <el-upload
              class="upload-demo"
              :auto-upload="false"
              action=""
              :before-upload="beforeUpload"
              :on-change="handleFileChange"
              :show-file-list="false"
            >
              <el-button type="primary" icon="el-icon-upload2">选择图片</el-button>
            </el-upload>
            <div>
              <img v-if="uploadImageUrl && uploadImageUrl != ''" :src="uploadImageUrl" alt="photo" class="photo-image" />
            </div>
          </el-form-item>
          
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addEvent">添加</el-button>
        </div>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import timelineEventApi from "@/api/timelineEventApi";

export default {
  name: "TimelinePage",
  data() {
    return {
      // 时间轴数据
      timelineData: [],
      // 弹框显示状态
      dialogVisible: false,
      // 新事件的数据
      newEvent: {
        timestamp: "",
        icon: "el-icon-time",
        color: "#FF6F61",
        title: "",
        content: "",
        image: "", // 图片路径
      },
      // 时间轴布局方向
      timelineDirection: "vertical",
      loading: false,
      uploadImageUrl: "",
      
    };
  },
  created() {
    this.fetchTimelineData();
    this.adjustTimelineDirection();
    window.addEventListener("resize", this.adjustTimelineDirection);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.adjustTimelineDirection);
  },
  methods: {
    getRandomVividColor() {
      // 随机生成醒目颜色的RGB值
      const r = Math.floor(Math.random() * (256 - 180) + 180); // 红色值高，突出红色
      const g = Math.floor(Math.random() * (180 - 100) + 100); // 绿色值适中
      const b = Math.floor(Math.random() * (150 - 50) + 50);   // 蓝色值较低，避免过多蓝色

      // 转换为16进制并返回
      return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
    },

    componentToHex(c) {
      const hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 获取月份，注意月份从0开始
      const day = String(date.getDate()).padStart(2, '0'); // 获取日期
      const hours = String(date.getHours()).padStart(2, '0'); // 获取小时
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 获取分钟
      const seconds = String(date.getSeconds()).padStart(2, '0'); // 获取秒

      // 返回格式化后的时间字符串
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 获取时间轴数据
    async fetchTimelineData() {
      try {
        const response = await timelineEventApi.queryByPage({});
        this.timelineData = response.data || [];
      } catch (error) {
        this.$message.error("获取时间线数据失败");
        console.error(error);
      }
    },
    // 动态调整时间轴方向
    adjustTimelineDirection() {
      this.timelineDirection = window.innerWidth <= 768 ? "horizontal" : "vertical";
    },
    // 打开对话框
    openDialog() {
      this.dialogVisible = true;
    },
    // 添加事件
    async addEvent() {
      this.newEvent.color = this.getRandomVividColor();
      if (this.newEvent.title && this.newEvent.content) {
        try {
          this.newEvent.timestamp = new Date().getTime();
          const response = await timelineEventApi.addTimelineEvent(this.newEvent);
          this.timelineData.push(response.data);
          this.resetForm();
          this.fetchTimelineData();
          this.dialogVisible = false;
          this.uploadImageUrl = "";
          this.$message.success("事件添加成功");

          
        } catch (error) {
          this.$message.error("添加事件失败");
          console.error(error);
        }
      } else {
        this.$message.error("请填写完整的事件信息");
      }
    },
    // 重置表单
    resetForm() {
      this.newEvent = {
        timestamp: "",
        icon: "el-icon-time",
        color: "#FF6F61",
        title: "",
        content: "",
        image: "",
      };
    },
    // 上传图片前的钩子函数
    beforeUpload(file) {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        this.$message.error("只能上传图片文件");
      }
      return isImage;
    },
    // 处理文件选择
    handleFileChange(file) {
      console.log("handleFileChange");
      if (file && file.raw) {
        this.uploadImage(file.raw);
      }
    },
    // 上传图片并获取文件路径
    async uploadImage(formData) {
      try {
        this.loading = true;
        const response = await timelineEventApi.uploadImage(formData);
        console.log("上传图片返回的数据:",response);
        if (response && response.data) {
          this.uploadImageUrl = response.data;
          this.newEvent.image = response.data; // 假设返回的路径在 `response.data.url` 中
          this.$message.success("图片上传成功");
        }
      } catch (error) {
        this.$message.error("图片上传失败");
        console.error(error);
      }finally{
        this.loading = false
      }
    },
  },
};
</script>

<style scoped>
.timeline-container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #fdf2f2;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

h3 {
  font-size: 20px;
  font-weight: bold;
  color: #ff6b81;
  margin-bottom: 10px;
}

p {
  font-size: 14px;
  color: #6c6c6c;
}

.el-timeline-item {
  border: none;
}

.el-timeline-item__tail {
  background-color: #ff6f61;
}

.el-timeline-item__icon {
  border: 2px solid #ff6f61;
  background-color: #fff;
}

.timeline-item-content {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-image {
  width: 10%;
  height: auto;
  margin-top: 15px;
  border-radius: 8px;
}

/* 添加事件图标样式 */
.add-icon {
  width: 30px;  /* 设置图标的宽度 */
  height: 30px;
}

.add-icon:hover {
  background-color: #ff6f61;
  color: white;
}

/* 响应式样式 */
@media (max-width: 768px) {
  .timeline-container {
    width: 95%;
    padding: 20px 10px;
  }

  .timeline-item-content {
    padding: 10px;
  }

  .timeline-image {
    margin-top: 10px;
  }

  .add-icon {
    font-size: 30px;
    top: 10px;
    right: 10px;
    padding: 5px;
  }

  .el-dialog {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  h3 {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }

  .el-timeline-item__tail {
    background-color: #ff6f61;
    width: 2px;
  }
}
.add-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ff6b81;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.add-button:hover {
  background-color: #ff4e4e;
}
/* 自定义对话框宽度 */
.custom-dialog {
  max-width: 400px;
  margin: 0 auto;
}

/* 对话框标题字体调整 */
.custom-dialog .el-dialog__header {
  font-size: 18px;
  padding: 10px 15px;
  background-color: #ff6f61;
  color: white;
  border-bottom: none;
}

/* 对话框内容滚动支持 */
.custom-dialog .el-dialog__body {
  max-height: 70vh;
  overflow-y: auto;
  padding: 10px;
}

/* 按钮大小调整 */
.custom-dialog .el-button {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .custom-dialog {
    max-width: 95%;
  }

  .custom-dialog .el-dialog__body {
    padding: 10px;
  }

  .custom-dialog .el-form-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .custom-dialog .el-dialog__header {
    font-size: 16px;
    padding: 8px 10px;
  }

  .custom-dialog .el-dialog__body {
    max-height: 60vh;
  }
}
.el-button {
  background: linear-gradient(to right, #ff9a9e, #fad0c4); /* 粉色渐变 */
  color: #fff;
  border: none;
  border-radius: 20px; /* 圆角样式 */
  font-weight: bold;
  padding: 8px 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.photo-image {
  width: 25%;
  margin-top: 10px;
  
}
</style>
