import apiClient from "./api";

const timelineEventApi = {
  /**
   * 根据 ID 查询时间线事件
   * @param {Number} id 时间线事件 ID
   * @returns {Promise}
   */
  queryById(id) {
    return apiClient.get(`/timelineEvent/${id}`);
  },

  /**
   * 新增时间线事件
   * @param {Object} data 时间线事件数据
   * @returns {Promise}
   */
  addTimelineEvent(data) {
    return apiClient.post("/timelineEvent", data);
  },

  /**
   * 编辑时间线事件
   * @param {Object} data 时间线事件数据
   * @returns {Promise}
   */
  editTimelineEvent(data) {
    return apiClient.put("/timelineEvent", data);
  },

  /**
   * 根据 ID 删除时间线事件
   * @param {Number} id 时间线事件 ID
   * @returns {Promise}
   */
  deleteById(id) {
    return apiClient.delete("/timelineEvent", { params: { id } });
  },

  /**
   * 分页查询时间线事件
   * @param {Object} filter 筛选条件
   * @returns {Promise}
   */
  queryByPage(filter) {
    return apiClient.get("/timelineEvent", { params: filter });
  },
  /**
   * 上传图片
   * @param {File} file 上传的图片文件
   * @returns {Promise} 返回图片上传的 URL
   */
  uploadImage(file) {
    const formData = new FormData();
    formData.append("file", file);  // 将图片文件添加到 FormData 中
    return apiClient.post("/timelineEvent/uploadImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data",  // 设置请求头为文件上传类型
      },
    });
  }
};

export default timelineEventApi;
