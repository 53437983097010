<template>
  <div id="map-container">
    <div id="map"></div>

    <!-- 照片模态框 -->
    <div
      class="photo-modal"
      v-if="showImages"
      @click.self="closeModal"
    >
      <div class="photo-content">
        <div
          v-for="(photo, index) in photosToShow"
          :key="index"
          class="photo-card"
          :style="{ animationDelay: `${index * 0.1}s` }"
        >
          <img :src="photo.path" alt="照片" />
        </div>

        <div class="photo-card" @click="triggerFileInput">
          <!-- 点击上传区域触发图片选择 -->
          <div class="photo-car upload-button">
            
          </div>
        </div>
      </div>

      <!-- 右上角关闭按钮 -->
      <div class="close-btn" @click="closeModal">×</div>
    </div>

    <el-dialog
      title="新增照片"
      v-model="addPhotoDialogVisible"
      width="94%"
    >
      <div v-loading="loading"> 
        <!-- 上传组件 -->
        <el-upload
          class="upload-demo"
          action="" 
          :auto-upload="false" 
          :file-list="fileList"
          multiple
          list-type="picture-card"
          :before-upload="handleBeforeUpload"
          @change="handleFileChange"
        >
          <i class="el-icon-plus"></i>
        </el-upload>

        <!-- 上传按钮 -->
        <el-button
          type="primary"
          @click="submitUpload"
          :disabled="fileList.length === 0"
        >
          上传
        </el-button>
      </div>
      
    </el-dialog>

    <!-- <el-dialog
      title="点亮"
      v-model="lightUp"
      width="88%"
    >
      <span>{{clickPlace.name}}未被点亮，确认点亮吗？</span>
      <template #footer>
        <el-button @click="lightUp = false">取消</el-button>
        <el-button type="primary" @click="lightUpArea">确认</el-button>
      </template>
    </el-dialog> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import chinaJson from "@/assets/map/china.json"; 
import mapApi from "@/api/mapApi"; // 导入mapApi
import photosApi from "@/api/photosApi";


export default {
  name: "Map",
  data() {
    return {
      photosToShow: [], // 当前展示的照片
      visitedPlaces: [], // 用户访问的地点
      showImages: false,
      addPhotoDialogVisible: false,
      fileList: [],
      clickPlace: null,
      loading: false,
      lightUp: false,
      lightUpItem: null,
    };
  },
  mounted() {
    this.fetchVisitedLocations(); // 获取用户访问的地点数据
  },
  methods: {
    async submitUpload() {
      console.log("this.fileList")
      if (this.fileList.length === 0) {
          this.$message.warning("请先选择图片！");
          return;
      }
      try {
        this.loading = true;
        const placeId = this.clickPlace.id; // 关联相册 ID

        // 构造 FormData 对象
        const formData = new FormData();
        formData.append("type", 2);
        formData.append("dataId", placeId);
        for (let i = 0; i < this.fileList.length; i++) {
          formData.append("files", this.fileList[i].raw); // 将每个文件添加到表单
        }

        console.log("formData",formData)
        // 调用批量上传接口
        const response = await photosApi.uploadPhotosBatch(formData);

        if (response.code === 200) {
          this.$message.success(`${response.data.length} 张图片上传成功`);
        
          // 更新照片列表
          this.handleMapClick(this.clickPlace);

          // 重置弹窗和文件列表
          this.fileList = [];
          this.fetchVisitedLocations();
          this.addPhotoDialogVisible = false;
        } else {
          this.$message.error(response.message || "批量上传失败");
        }
      } catch (error) {
        console.error("批量上传失败：", error);
        this.$message.error("批量上传失败，请稍后重试");
      }finally{
        this.loading = false;
      }
    },
    // 处理文件选择前的逻辑
    handleBeforeUpload(file) {
      // 可以在这里处理文件检查，如限制文件类型和大小
      // 例如：如果文件类型不是图片，则阻止上传
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        this.$message.error('只能上传图片文件');
      }
      return isImage;
    },

    // 文件选择变化时的处理
    handleFileChange(file, fileList) {
      // 更新文件列表
      this.fileList = fileList;
    },

    // 从后端获取用户访问的地点和照片信息
    async fetchVisitedLocations() {
      try {
        const response = await mapApi.getVisitedProvinces(); // 调用API
        this.visitedPlaces = response.data.map((place) => ({
          name: place.province, // 将省份名称映射为 name
          value: 1, // 可以根据实际需要调整 value，例如这里假设每个省份都标记为访问过（value: 1）
          id: place.id, // 保存省份的 id
        }));
        this.initMap(); // 初始化地图
      } catch (error) {
        console.error("Error fetching visited locations:", error);
      }
    },

    initMap() {
      // 注册中国地图
      echarts.registerMap("china", chinaJson);

      // 初始化地图
      const myChart = echarts.init(document.getElementById("map"));

      // 配置项
      const option = {
        backgroundColor: "#ffe4e1",
        tooltip: {
          trigger: "item",
          formatter: (params) => params.name || "未知区域",
        },
        visualMap: {
          show: false,
          min: 0,
          max: 1,
          inRange: {
            color: ["#ffd9d9", "#ff69b4"],
          },
        },
        series: [
          {
            name: "中国地图",
            type: "map",
            map: "china",
            roam: true, // 开启拖动和缩放
            label: {
              show: true,
              color: "#ff69b4",
            },
            emphasis: {
              itemStyle: {
                areaColor: "#ff6f91",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1.5,
              areaColor: "#ffd9d9",
            },
            data: this.visitedPlaces, // 使用处理后的数据
          },
        ],
      };

      // 设置选项
      myChart.setOption(option);

      // 点击事件
      myChart.on("click", async (params) => {
        const place = this.visitedPlaces.find((p) => p.name === params.name);
        if(place && place.id){
          await this.handleMapClick(place);
        }else{
          
          this.lightUp = true;
          this.lightUpItem = {};
          this.lightUpItem.name = params.name;
          this.lightUpItem.province = params.name;
          this.clickPlace = this.lightUpItem;
        }
        
      });
      

      // 监听窗口大小变化
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    async handleMapClick(place) {
      this.clickPlace = place;
      if (place && place.id) {
        this.showImages = true;
        try {
          // 通过地区ID查询照片
          const response = await mapApi.queryPhotoByLocationId(place.id);
          this.photosToShow = response.data || []; // 显示照片（如果有的话）
        } catch (error) {
          console.error("Error fetching photos:", error);
        }
      } else {
        
      }

    },
    async lightUpArea(){
      console.log("lightUpArea",this.lightUpItem)
      const response = await mapApi.addVisitedLocation(this.lightUpItem);
      if(response.code === 200){
        this.$message.success("点亮成功");
        this.lightUp = false;
        this.clickPlace = response.data || [];
        this.handleMapClick(this.clickPlace);
      }else{
        this.$message.error("点亮失败");
      }
    },
    closeModal() {
      this.photosToShow = [];
      this.showImages = false;
    },

    // 点击上传区域触发文件选择框
    triggerFileInput() {
      this.addPhotoDialogVisible=true;
    },

    // 处理文件选择
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
  },
};
</script>

<style scoped>
/* 页面整体样式 */
#map-container {
  width: 100%;
  height: 100vh; /* 使用全屏高度 */
  max-height: 600px;
  margin: 0 auto;
  padding: 10px;
  background: linear-gradient(135deg, #ffb6c1, #ffe4e1);
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(255, 182, 193, 0.3);
  position: relative;
  font-family: "Arial", sans-serif;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 3px 5px rgba(255, 105, 180, 0.2);
}

.photo-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  height: 70%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  animation: modalFadeIn 0.3s ease-in-out;
  overflow: hidden;
}

.photo-content {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.photo-content::-webkit-scrollbar {
  display: none;
}

.photo-card {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, opacity 0.3s ease;
  border: 2px solid pink;
}

.photo-card:hover {
  transform: scale(1.1);
}

.photo-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  z-index: 1100;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #ff69b4;
}

/* 上传按钮样式 */
.upload-button {
  width: 100px;
  height: 100px;
  border: 1px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}



/* 响应式优化 */
@media (max-width: 768px) {
  #map-container {
    height: 70vh;
    max-height: none;
    padding: 5px;
  }

  .photo-modal {
    width: 95%;
    height: 75%;
    max-height: 85%;
    padding: 5px;
  }

  .photo-card {
    width: 80px;
    height: 80px;
  }

  .photo-content {
    gap: 5px;
    padding: 5px;
  }
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


</style>
