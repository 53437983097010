import apiClient from "./api";

const albumsApi = {
  /**
   * 分页查询相册
   * @param {Object} params 查询参数
   * @returns {Promise}
   */
  queryByPage(params) {
    return apiClient.get("/albums", { params });
  },

  /**
   * 根据 ID 查询相册
   * @param {Number} id 相册 ID
   * @returns {Promise}
   */
  queryById(id) {
    return apiClient.get(`/albums/${id}`);
  },

  /**
   * 新增相册
   * @param {Object} data 相册数据
   * @returns {Promise}
   */
  addAlbum(data) {
    return apiClient.post("/albums", data);
  },

  /**
   * 编辑相册
   * @param {Object} data 相册数据
   * @returns {Promise}
   */
  editAlbum(data) {
    return apiClient.put("/albums", data);
  },

  /**
   * 删除相册
   * @param {Number} id 相册 ID
   * @returns {Promise}
   */
  deleteById(id) {
    return apiClient.delete("/albums", { params: { id } });
  },
};

export default albumsApi;
