<template>
  <div class="tag-management-page">
    <!-- 顶部工具栏 -->
    <header class="header">
      <el-input
        v-model="searchText"
        placeholder="搜索标签"
        clearable
        prefix-icon="el-icon-search"
        @input="filterTags"
        class="search-bar"
      />
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="openTagDialog"
        class="create-button"
      >
        新增标签
      </el-button>
    </header>

    <!-- 标签列表 -->
    <div class="tag-list">
      <el-row gutter="20">
        <el-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(tag, index) in filteredTags"
          :key="index"
        >
          <div class="tag-card">
            <h3 class="tag-name">{{ tag.name }}</h3>
            <p class="tag-usage">使用次数：{{ tag.usageCount }}</p>
            <div class="tag-actions">
              <el-button
                type="text"
                icon="el-icon-edit"
                @click="editTag(tag)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                icon="el-icon-delete"
                @click="deleteTag(index)"
              >
                删除
              </el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 新增/编辑标签弹窗 -->
    <el-dialog
      title="标签管理"
      v-model:visible="tagDialogVisible"
      width="30%"
    >
      <el-form :model="currentTag">
        <el-form-item label="标签名称" :label-width="80">
          <el-input v-model="currentTag.name" placeholder="请输入标签名称" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="tagDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveTag">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "TagManagementPage",
  data() {
    return {
      searchText: "", // 搜索关键字
      tags: [
        { name: "旅行", usageCount: 20 },
        { name: "海滩", usageCount: 15 },
        { name: "家庭", usageCount: 10 },
        { name: "自然", usageCount: 8 },
      ], // 标签列表
      filteredTags: [], // 过滤后的标签
      tagDialogVisible: false, // 控制新增/编辑弹窗显示
      currentTag: { name: "" }, // 当前编辑的标签
      isEditMode: false, // 是否为编辑模式
    };
  },
  created() {
    this.filteredTags = this.tags; // 初始化展示所有标签
  },
  methods: {
    filterTags() {
      // 根据搜索关键字过滤标签
      this.filteredTags = this.tags.filter((tag) =>
        tag.name.includes(this.searchText)
      );
    },
    openTagDialog() {
      // 打开新增标签弹窗
      this.isEditMode = false;
      this.currentTag = { name: "" };
      this.tagDialogVisible = true;
    },
    editTag(tag) {
      // 打开编辑标签弹窗
      this.isEditMode = true;
      this.currentTag = { ...tag };
      this.tagDialogVisible = true;
    },
    saveTag() {
      // 保存标签
      if (!this.currentTag.name) {
        this.$message.error("标签名称不能为空！");
        return;
      }
      if (this.isEditMode) {
        // 编辑模式
        const index = this.tags.findIndex(
          (tag) => tag.name === this.currentTag.name
        );
        if (index !== -1) {
          this.tags[index] = { ...this.currentTag };
        }
        this.$message.success("标签编辑成功！");
      } else {
        // 新增模式
        this.tags.push({ ...this.currentTag, usageCount: 0 });
        this.$message.success("标签新增成功！");
      }
      this.filteredTags = this.tags;
      this.tagDialogVisible = false;
    },
    deleteTag(index) {
      // 删除标签
      this.$confirm("确定删除该标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tags.splice(index, 1);
          this.filteredTags = this.tags;
          this.$message.success("标签删除成功！");
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
  },
};
</script>
<style scoped>
.tag-management-page {
  padding: 20px;
  font-family: "Arial", sans-serif;
  background: #f9f9f9;
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  width: 300px;
}

.create-button {
  height: 40px;
}

.tag-list {
  margin-top: 20px;
}

.tag-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tag-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.tag-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.tag-usage {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.tag-actions .el-button {
  margin: 0 5px;
}
</style>
