import apiClient from "./api";

const usersApi = {
  /**
   * 分页查询用户
   * @param {Object} params 查询参数
   * @returns {Promise}
   */
  queryByPage(params) {
    return apiClient.get("/users", { params });
  },

  /**
   * 根据 ID 查询用户
   * @param {Number} id 用户 ID
   * @returns {Promise}
   */
  queryById(id) {
    return apiClient.get(`/users/${id}`);
  },

  /**
   * 新增用户
   * @param {Object} data 用户数据
   * @returns {Promise}
   */
  addUser(data) {
    return apiClient.post("/users", data);
  },

  /**
   * 编辑用户
   * @param {Object} data 用户数据
   * @returns {Promise}
   */
  editUser(data) {
    return apiClient.put("/users", data);
  },

  /**
   * 根据 ID 删除用户
   * @param {Number} id 用户 ID
   * @returns {Promise}
   */
  deleteById(id) {
    return apiClient.delete("/users", { params: { id } });
  },

  /**
   * 用户登录
   * @param {Object} data 登录数据 { email, password }
   * @returns {Promise}
   */
  login(data) {
    return apiClient.post("/users/login", data);
  },
};

export default usersApi;
