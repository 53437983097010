<template>
  <div class="photo-wall-page" v-loading='isLoad'>
    <!-- 标题部分 -->
    <header class="header">
      <h1>{{albumInfo.title}}</h1>
      <p>{{albumInfo.description}}</p>
    </header>

    <!-- 搜索与筛选 -->
    <div class="search-filter">
      <el-input
        v-model="searchText"
        placeholder="搜索照片"
        clearable
        prefix-icon="el-icon-search"
        @input="filterPhotos"
        class="search-bar"
      />
      <!-- <el-select
        v-model="selectedTag"
        placeholder="按标签筛选"
        clearable
        class="filter-select"
        @change="filterPhotos"
      >
        <el-option
          v-for="tag in tags"
          :key="tag"
          :label="tag"
          :value="tag"
        ></el-option>
      </el-select> -->
    </div>

    <!-- 照片墙展示区域 -->
    <div class="photo-wall">
      


      <!-- 照片展示框 -->
      <div
        v-for="(photo, index) in filteredPhotos"
        :key="photo.photoId"
        class="photo-frame"
        :style="{ animationDelay: `${index * 0.2}s` }"
        @click="goToShowDetail(photo)"
      >
        <div class="pin"></div>
        <div class="photo-content">
          <img :src="photo.src" alt="photo" class="photo-image" />
        </div>
        <span class="photo-caption">{{ photo.caption }}</span>
        <div class="delete-icon" @click.stop="deletePhoto(photo.photoId)">×</div>
      </div>

      <!-- 新增照片框 -->
      <div class="photo-frame add-photo" @click="openAddPhotoDialog">
        <div class="pin"></div> <!-- 图钉 -->
        <i class="el-icon-plus add-photo-icon"></i>
        <span class="add-photo-text">新增照片</span>
      </div>
    </div>
    <el-dialog
      title="新增照片"
      v-model="addPhotoDialogVisible"
      width="94%"
    >
      <div v-loading="isLoad">
        <!-- 上传组件 -->
        <el-upload
          class="upload-demo"
          action="" 
          :auto-upload="false" 
          :file-list="fileList"
          multiple
          list-type="picture-card"
          :before-upload="handleBeforeUpload"
          @change="handleFileChange"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        
        <!-- 上传按钮 -->
        <el-button
          type="primary"
          @click="submitUpload"
          :disabled="fileList.length === 0"
        >
          上传
        </el-button>
      </div>  
    </el-dialog>


  </div>
</template>

<script>
import photosApi from "@/api/photosApi";
import albumsApi from "@/api/albumsApi";

export default {
  name: "PhotoWallPage",
  props: ["albumId"],
  data() {
    return {
      searchText: "",
      selectedTag: "",
      tags: ["旅行", "约会", "家庭", "自然"],
      photoList: [],
      filteredPhotos: [],
      addPhotoDialogVisible: false, // 控制新增照片弹窗的显示
      fileList: [], 
      isLoad: false,
      albumInfo: {},
    };
  },
  created() {
    this.fetchPhotos();
  },
  methods: {
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },

    async fetchPhotos() {
      console.log('this.albumId',this.albumId)
      try {
        var response = await albumsApi.queryById(this.albumId);
        if (response.code === 200) {
          this.albumInfo = response.data;
        }
        response = await photosApi.getPhotos({ albumId: this.albumId });
        if (response.code === 200) {
          this.photoList = response.data.map((photo) => ({
            photoId: photo.photoId,
            src: photo.path,
            caption: `时间: ${new Date(photo.createTime).toLocaleDateString()}`,
            tags: photo.tags,
          }));
          this.filteredPhotos = this.photoList;
        } else {
          this.$message.error(response.message || "获取照片列表失败");
        }
      } catch (error) {
        console.error("获取照片列表失败：", error);
        this.$message.error("获取照片列表失败");
      }
    },
    /**
     * 打开新增照片弹窗
     */
    openAddPhotoDialog() {
      this.addPhotoDialogVisible = true;
    },
    /**
     * 提交图片上传
     */
    async submitUpload() {
      console.log("this.fileList")
      if (this.fileList.length === 0) {
          this.$message.warning("请先选择图片！");
          return;
      }
      try {
        
        this.isLoad = true;
        const albumId = this.albumId; // 关联相册 ID
        console.log('this.fileList',this.fileList);
        // 构造 FormData 对象
        const formData = new FormData();
        formData.append("type", 1);
        formData.append("dataId", albumId);
        for (let i = 0; i < this.fileList.length; i++) {
          formData.append("files", this.fileList[i].raw); // 将每个文件添加到表单
        }


        // 调用批量上传接口
        const response = await photosApi.uploadPhotosBatch(formData);

        if (response.code === 200) {
          this.$message.success(`${response.data.length} 张图片上传成功`);
        
          // 更新照片列表
          this.fetchPhotos();

          // 重置弹窗和文件列表
          this.fileList = [];
          this.addPhotoDialogVisible = false;
        } else {
          this.$message.error(response.message || "批量上传失败");
        }
      } catch (error) {
        console.error("批量上传失败：", error);
        this.$message.error("批量上传失败，请稍后重试");
      }finally{
        this.isLoad = false;
      }
    },



    /**
     * 过滤照片列表
     */
    filterPhotos() {
      this.filteredPhotos = this.photoList.filter((photo) => {
        const matchesSearch =
          !this.searchText ||
          photo.caption.includes(this.searchText) ||
          photo.tags.some((tag) => tag.includes(this.searchText));
        const matchesTag =
          !this.selectedTag || photo.tags.includes(this.selectedTag);
        return matchesSearch && matchesTag;
      });
    },
    /**
     * 上传前校验
     */
    handleBeforeUpload(file) {
      const isImage = file.type.startsWith("image/");
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error("只能上传图片文件！");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB！");
        return false;
      }
      this.fileList.push(file);
      return false;
    },
    goToShowDetail(photo) {
      this.$router.push({ name: "ImageDetail", params: { photoId: photo.photoId } });
    },
    /**
     * 删除照片
     * @param {String} photoId 照片 ID
     */
    async deletePhoto(photoId) {
      try {
        const confirm = await this.$confirm(
          "确定要删除这张照片吗？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch(() => false);

        if (!confirm) return;

        const response = await photosApi.deleteById({photoId:photoId,albumId:this.albumId});
        if (response.code === 200) {
          this.$message.success("照片删除成功！");
          this.fetchPhotos(); // 更新照片列表
        } else {
          this.$message.error(response.message || "删除照片失败！");
        }
      } catch (error) {
        console.error("删除照片失败：", error);
        this.$message.error("删除照片失败，请稍后重试");
      }
    },
  },
};
</script>

<style scoped>
/* 页面整体样式 */
.photo-wall-page {
  font-family: "Arial", sans-serif;
  text-align: center;
  padding: 20px;
  background: url("@/assets/images/image_wall.png") no-repeat center center fixed; /* 使用背景图片 */
  background-size: cover; /* 确保图片覆盖整个背景 */
  min-height: 100vh;
}

/* 标题部分 */
.header {
  margin-bottom: 40px;
  padding: 40px 20px;
  background: linear-gradient(135deg, #ff9aa2, #ffb6c1);
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(255, 105, 180, 0.3);
}

.header h1 {
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: bold;
  text-shadow: 0 3px 6px rgba(255, 182, 193, 0.5);
}

.header p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
}


/* 搜索与筛选部分 */
.search-filter {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.8); /* 半透明背景，适配墙面风格 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: rotate(1.5deg); /* 增加轻微的旋转效果 */
  width: 80%;
  max-width: 240px;
  margin: 0 auto; /* 居中 */
}

.search-bar {
  width: 300px;
}

.filter-select {
  width: 200px;
}

/* 照片墙展示 */
.photo-wall {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); /* 自适应列宽 */
  gap: 20px;
  justify-content: end; /* 改为从右对齐排列 */
  padding-top: 10px;
}

/* 单个照片框架 */

.photo-frame {
  position: relative;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  animation: sway 4s infinite ease-in-out;
  animation-delay: 0s;
  transform-origin: top center;
  transition: box-shadow 0.3s ease;
  width: 100%;
  max-width: 170px; /* 限制最大宽度 */
  min-height: 300px; /* 增加最小高度 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photo-frame:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* 图钉 */
.pin {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* 照片内容 */
.photo-content {
  padding: 15px;
  font-size: 14px;
  color: #888;
  text-align: center;
}

.photo-image {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 100%; /* 确保图片不会超出框架 */
  max-width: 100%; /* 确保图片宽度适配框架 */
  object-fit: contain; /* 图片内容适应框架 */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 照片说明 */
.photo-caption {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  text-align: center;
  font-style: italic;
}

/* 微风轻拂动画（带随机性） */
@keyframes sway {
  0% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}

/* 标题部分 */
.header {
  margin-bottom: 40px;
  padding: 20px;
  background: #fff;
  color: #444;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: fit-content;
  margin: 0 auto 40px auto; /* 居中 */
  transform: rotate(-1.5deg); /* 增加轻微的旋转，模仿手工贴上去的感觉 */
  position: relative;
}

.header:before,
.header:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  top: -10px;
  left: calc(50% - 10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.8), -1px -1px 0 rgba(255, 255, 255, 0.8);
}

.header p {
  font-size: 14px;
  font-style: italic;
  color: #666;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.8), -1px -1px 0 rgba(255, 255, 255, 0.8);
}



.search-bar {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-select {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
/* 新增照片框样式 */
.add-photo {
  display: flex;
  flex-direction: column; /* 垂直排列内容 */
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  border: 2px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
  position: relative; /* 确保图钉正确定位 */
  min-height: 300px; /* 保持与其他相框一致 */
  transition: background 0.3s ease;
}

.add-photo:hover {
  background: #e3e3e3;
}

.add-photo-icon {
  font-size: 36px;
  color: #aaa;
}

.add-photo-text {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
  text-align: center;
}

/* 删除图标样式 */
.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  font-size: 20px;
  font-weight: bold;
  color: #ff6b6b;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.delete-icon:hover {
  background-color: #ff4b4b;
  color: #fff;
}

</style>
