import apiClient from "./api";

const weatherApi = {
  getWeather(params) {
    return apiClient.get(`/weather/getWeather/${params}`);
  },
  getZoneCode(latitude, longitude) {
    // 调用后端 getZoneCode 接口，传入经纬度
    return apiClient.get(`/weather/getZoneCode`, {
      params: {
        latitude: latitude,
        longitude: longitude,
      },
    });
  },
};

export default weatherApi;
