import apiClient from "./api";

const visitedLocationsApi = {
  /**
   * 根据 ID 查询访问记录
   * @param {Number} id 访问记录 ID
   * @returns {Promise}
   */
  queryById(id) {
    return apiClient.get(`/visitedLocations/${id}`);
  },

  /**
   * 新增访问记录
   * @param {Object} data 访问记录数据
   * @returns {Promise}
   */
  addVisitedLocation(data) {
    return apiClient.post("/visitedLocations", data);
  },

  /**
   * 编辑访问记录
   * @param {Object} data 访问记录数据
   * @returns {Promise}
   */
  editVisitedLocation(data) {
    return apiClient.put("/visitedLocations", data);
  },

  /**
   * 根据 ID 删除访问记录
   * @param {Number} id 访问记录 ID
   * @returns {Promise}
   */
  deleteById(id) {
    return apiClient.delete("/visitedLocations", { params: { id } });
  },

  /**
   * 获取用户访问过的省份
   * @returns {Promise}
   */
  getVisitedProvinces() {
    return apiClient.get("/visitedLocations/visited/provinces");
  },

  queryPhotoByLocationId(id) {
    return apiClient.get(`/visitedLocations/photo/${id}`);
  },
};

export default visitedLocationsApi;
