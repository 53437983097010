<template>
  <div class="love-list-container">
    <!-- 背景图 -->
    <div class="background-image"></div>

    <!-- 添加标签按钮 -->
    <!-- <div class="add-tab-header">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="showAddTabDialog"
      >
        添加标签
      </el-button>
    </div> -->

    <!-- 标签导航栏 -->
    <div class="tabs">
      <el-tabs v-model="activeTab" type="card" class="tabs-bar" editable @tab-click="onTabClick" @edit="handleTabsEdit">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.id"
          :label="tab.label"
          :name="tab.name"
        ></el-tab-pane>
      </el-tabs>
    </div>
    
    <!-- 标签对应的清单 -->
    <div class="list-container">
      <div class="list-header">
        <span>清单项 {{compledCount}}/{{totalCount}}</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="showAddItemDialog"
        >
          添加清单项
        </el-button>
      </div>
      
      <el-row :gutter="20">
        <el-col
          :span="24"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24">
          <el-progress
            :text-inside="true"
            :stroke-width="10"
            :percentage="percentage"
          />
        </el-col>
        <el-col
          :span="24"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(item, index) in items"
          :key="index"
          class="detail-col"
          :style="{'line-height': 'normal'}"
        >
          <el-card 
            :class="item.status === 1 ? 'list-item-done' : 'list-item-pending'" :body-style="{ padding: '0px' }"
            @click="item.status === 1 && showItem(item)"
          >
            <div class="item-content">
              <div class="item-left">
                <img :src="item.image" alt="item image" class="item-image" />
              </div>
              <div class="item-right">
                <div class="item-right-information">
                  <div class="item-title">{{ item.title }}</div>
                  <div class="item-description">{{ item.content }}</div>
                </div>
                
                <div class="item-action">
                  <img
                    :src="item.status === 1 ? require('@/assets/images/gou_lv.png') : require('@/assets/images/gou.png')"
                    :alt="item.status === 1 ? '完成图标' : '未完成图标'"
                    class="check-icon"
                    :class="{ disabled: item.status === 1 }"
                    :style="{ cursor: item.status === 1 ? 'not-allowed' : 'pointer' }"
                    @click="item.status !== 1 && showConfirmDialog(item)"
                  />
                </div>
              </div>
            </div>
          </el-card>

        </el-col>


      </el-row>
    </div>

    <!-- 添加标签弹窗 -->
    <el-dialog
      title="添加标签"
      v-model="addTabDialogVisible"
      width="88%"
    >
      <el-form :model="newTab" ref="addTabForm" label-width="80px">
        <el-form-item label="标签名称" required>
          <el-input v-model="newTab.label" placeholder="请输入标签名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="addTabDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addTab">确定</el-button>
      </template>
    </el-dialog>

    <!-- 添加清单项弹窗 -->
    <el-dialog
      title="添加清单项"
      v-model="addItemDialogVisible"
      width="88%"
    >
      <el-form :model="newItem" ref="addItemForm" label-width="80px">
        <el-form-item label="标题" required>
          <el-input v-model="newItem.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="内容" required>
          <el-input
            type="textarea"
            v-model="newItem.content"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="addItemDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addChecklistItem">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog
      title="确认操作"
      v-model="confirmDialogVisible"
      width="88%"
    >
      <span>确定要将该清单项标记为已完成吗？</span>
      <template #footer>
        <el-button @click="confirmDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateStatus">确认</el-button>
      </template>
    </el-dialog>

    <el-dialog
      title="添加新标签"
      v-model="dialogVisible"
      @close="handleDialogClose"
      width="88%"
    >
      <el-form :model="newTab" label-width="80px">
        <el-form-item label="标签名称">
          <el-input v-model="newTab.label" placeholder="请输入标签名称"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addTab">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="详情"
      v-model="showInformation"
      @close="handleDialogClose"
      width="88%"
    >
      <el-form :model="selectedItem" label-width="80px">
        <el-form-item label="标签标题">
          <el-input v-model="selectedItem.title" placeholder="请输入标签名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="标签内容">
          <el-input v-model="selectedItem.content" placeholder="请输入标签名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="完成时间">
          <el-input v-model="selectedItem.showTime" placeholder="请输入标签名称" disabled></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/loveChecklistApi";
import apiItems from "@/api/loveChecklistItems";
export default {
  data() {
    return {
      activeTab: "all", // 当前选中的标签，默认值为 "all"
      tabs: [], // 从后端获取的标签列表
      items: [], // 当前标签的清单项
      addItemDialogVisible: false, // 控制弹窗显示
      newItem: {
        title: "",
        content: "",
        image: ""
      },
      addTabDialogVisible: false, // 控制添加标签弹窗显示
      newTab: {
        label: "" // 新标签的名称
      },
      confirmDialogVisible: false, // 确认弹窗状态
      selectedItem: null, // 选中的清单项
      dialogVisible: false,
      newTab: { label: '', name: '' },
      showInformation: false,
      compledCount: 0,
      totalCount: 0,
      percentage: 0
    };
  },
  watch: {
    // 监听 items 数组的变化
    items: {
      handler() {
        this.updateCountsAndPercentage();
      },
      deep: true // 深度监听数组内部的变化
    }
  },

  created() {
    this.fetchTabs(); // 初始化时加载标签列表
    this.updateCountsAndPercentage();
  },
  methods: {
    updateCountsAndPercentage() {
      // 计算总数
      this.totalCount = this.items.length;

      // 计算 status 为 1 的数量
      this.compledCount = this.items.filter(item => item.status === 1).length;

      // 计算比值（百分比，取整数部分）
      if (this.totalCount > 0) {
        this.percentage = Math.floor((this.compledCount / this.totalCount) * 100);
      } else {
        this.percentage = 0;
      }
    },
    showItem(item){
      console.log("item", item);
      this.showInformation = true;
      this.selectedItem = item;
      this.selectedItem.showTime = this.formatDate(this.selectedItem.compledTime);
    },
    handleTabsEdit(targetName, action) {
      console.log("targetName", targetName);
      console.log("tabs", this.tabs);
      
      if (action === 'add') {
        this.dialogVisible = true; // 弹出添加标签的弹窗
      } else if (action === 'remove') {
        // 遍历 tabs，根据 targetName 找到对应的 tab
        const targetTab = this.tabs.find(tab => tab.name === targetName);
        
        // 如果找到了该 tab，传递该 tab 的 id 给 removeTab 方法
        if (targetTab) {
          this.removeTab(targetTab.id);
        } else {
          this.$message.error('找不到要删除的标签');
        }
      }
    },

    // 删除标签
    removeTab(id) {
      this.$confirm('您确定要删除这个标签吗?', '删除标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {

        
          // 调用 API 删除 tab，假设 deleteById 是你的删除接口
          api.deleteById(id)
            .then(() => {
              // 删除成功后从 tabs 中移除该 tab
              this.tabs = this.tabs.filter(tab => tab.id !== id);
              this.$message.success('标签已删除');
              this.items = [];
            })
            .catch(err => {
              this.$message.error('删除失败：' + err.message);
            });
        
      })
    },


    // 关闭弹窗时的处理
    handleDialogClose() {
      this.newTab = { label: '', name: '' }; // 重置输入框数据
    },
    // 显示确认弹窗
    showConfirmDialog(item) {
      console.log("item",item);
      this.selectedItem = item;
      this.confirmDialogVisible = true;
    },
    // 更新清单项状态
    async updateStatus() {
      console.log("updateStatus",this.selectedItem)
      if (!this.selectedItem) return;

      try {
        const t = new Date().toISOString();
        const response = await apiItems.editChecklistItem({
          id: this.selectedItem.id,
          status: 1,
          compledTime: t
        });

        if (response && response.data) {
          this.$message.success("状态更新成功");
          this.selectedItem.status = 1; // 本地数据更新
          this.confirmDialogVisible = false; // 关闭弹窗
          const itemToUpdate = this.items.find(item => item.id === this.selectedItem.id);
          if (itemToUpdate) {
            itemToUpdate.completedTime = t;
          }
        }
      } catch (error) {
        console.error("Failed to update status:", error);
        this.$message.error("状态更新失败");
      }
    },
    /**
     * 显示添加标签弹窗
     */
    showAddTabDialog() {
      console.log("showAddTabDialog")
      this.newTab = { label: "" };
      this.addTabDialogVisible = true;
    },
    /**
     * 添加新标签
     */
    async addTab() {
      try {
        if (!this.newTab.label) {
          this.$message.error("请输入标签名称");
          return;
        }
        const payload = { name: this.newTab.label };
        const response = await api.addChecklist(payload); // 调用添加标签的 API
        if (response && response.data) {
          this.$message.success("标签添加成功");
          this.dialogVisible = false; // 关闭弹窗
          // 更新标签列表并选中新标签
          const newTab = { id: response.data.id, label: response.data.name, name: response.data.name };
          this.tabs.push(newTab);
          this.activeTab = newTab.name; // 设置为当前选中
          this.items = []; // 清空当前清单项
        }
      } catch (error) {
        console.error("Failed to add tab:", error);
        this.$message.error("添加标签失败");
      }
    },
    /**
     * 获取标签列表
     */
    async fetchTabs() {
      try {
        debugger;
        const response = await api.queryByPage(); // 调用 API 获取标签
        if (response && response.data) {
          this.tabs = response.data.map((item) => ({
            id: item.id,
            label: item.name,
            name: item.name
          }));
          console.log("Tabs:", this.tabs);

          // 加载第一个标签的清单项
          if (this.tabs.length > 0) {
            this.activeTab = this.tabs[0].name;
            this.fetchItems(this.tabs[0].id);
          }
        }
      } catch (error) {
        console.error("Failed to fetch tabs:", error);
      }
    },
    /**
     * 获取清单项
     * @param {Number} checklistId 标签的 ID
     */
    async fetchItems(checklistId) {
      console.log("checklistId:", checklistId);
      try {
        const response = await apiItems.queryByPage({checklistId:checklistId}); // 调用 API 获取清单项
        if (response && response.data) {
          this.items = response.data.map((item) => ({
            title: item.title,
            content: item.content,
            image: item.image || require('@/assets/images/file.png') ,
            status: item.status,
            id: item.id,
            compledTime: item.compledTime
          }));
          console.log("Items123:", this.items);
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    },
    /**
     * 标签点击事件
     * @param {Object} tab 点击的标签对象
     */
    onTabClick(tab,event) {
      console.log("Tab Clicked:", tab.props.label);
      console.log("this.tabs:", this.tabs);
      const selectedTab = this.tabs.find((t) => t.label == tab.props.label);
      console.log("Selected Tab:", selectedTab);
      if (selectedTab) {
        this.fetchItems(selectedTab.id); // 根据选中的标签加载清单项
      }
    },
    showAddItemDialog() {
      console.log("showAddItemDialog")
      this.newItem = {
        title: "",
        content: "",
        image: ""
      };
      this.addItemDialogVisible = true;
    },
    /**
     * 添加清单项
     */
    async addChecklistItem() {
      try {
        if (!this.newItem.title || !this.newItem.content) {
          this.$message.error("请填写完整信息");
          return;
        }
        const checklistId = this.tabs.find((tab) => tab.name === this.activeTab).id;
        const payload = {
          checklistId: checklistId,
          ...this.newItem
        };
        const response = await apiItems.addChecklistItem(payload); // 调用添加清单项 API
        if (response && response.data) {
          this.$message.success("清单项添加成功");
          this.addItemDialogVisible = false;
          this.fetchItems(checklistId); // 刷新列表
        }
      } catch (error) {
        console.error("Failed to add item:", error);
        this.$message.error("添加清单项失败");
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString); // 解析日期字符串
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      // 格式化为 'YYYY-MM-DD HH:mm:ss'
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

  }
};
</script>

<style scoped>
.love-list-container {
  /* padding: 10px; */
  background-color: #fef1f6; /* 浅粉色背景 */
  /* border-radius: 15px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 97vh;
  border: 1px solid #e91e63;
  /* width: calc(100% - 11px); */
}

/* 背景图 */
.background-image {
  width: 100%;
  height: 200px;
  background: url('@/assets/images/lovelist_bg.png') no-repeat center center;
  background-size: cover;
  border-radius: 15px;
  opacity: 0.8;
}

/* 标签导航栏 */
.tabs {
  margin-top: -40px;
}

.tabs-bar .el-tab {
  color: #e91e63; /* 浪漫粉色 */
  font-weight: bold;
}

.tabs-bar .el-tab.is-active {
  color: #fff;
  background-color: #e91e63; /* 浪漫粉色 */
  border-radius: 12px;
}

.tabs-bar .el-tabs__header {
  background-color: #fce4ec; /* 轻柔的粉色背景 */
}

/* 清单项内容 */
.item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #fff; /* 白色背景，突出内容 */
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.item-content:hover {
  transform: scale(1.02);
}

/* 左侧图片 */
.item-left {
  width: 12%;
}

.item-image {
  width: 100%;
  height: auto;
  max-height: 80px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #e91e63; /* 增加边框 */
}


/* 标题样式 */
.item-title {
  font-size: 18px;
  font-weight: bold;
  color: #e91e63; /* 粉色标题 */
}

/* 详情样式 */
.item-description {
  font-size: 14px;
  color: #555;
  
  }
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 10px;
}

.list-header span {
  font-size: 18px;
  font-weight: bold;
}

/* 美化按钮 */
.el-button {
  background: linear-gradient(to right, #ff9a9e, #fad0c4); /* 粉色渐变 */
  color: #fff;
  border: none;
  border-radius: 20px; /* 圆角样式 */
  font-weight: bold;
  padding: 8px 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.el-button:hover {
  background: linear-gradient(to right, #fad0c4, #ff9a9e); /* 悬停时颜色反转 */
  transform: translateY(-2px); /* 悬停时略微上移 */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* 增加阴影 */
}

.el-button:active {
  transform: translateY(0); /* 按下时还原位置 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-tab-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.add-tab-header .el-button {
  background: linear-gradient(to right, #ff9a9e, #fad0c4);
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.add-tab-header .el-button:hover {
  background: linear-gradient(to right, #fad0c4, #ff9a9e);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
/* 标签导航栏容器 */
.tabs {
  margin-top: -40px;
  border-bottom: 2px solid #ffd1dc; /* 浅粉色下边框 */
  padding: 10px 0;
  background: linear-gradient(to right, #ffe4e1, #fad0c4); /* 柔和的粉色渐变背景 */
  border-radius: 15px; /* 圆角设计 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微阴影提升层次 */
}

/* 标签整体容器样式 */
.tabs-bar {
  background-color: #fff; /* 白色背景，简洁柔和 */
  border-radius: 15px; /* 圆角设计 */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  position: relative;
}

/* 心形装饰图案（位于标签栏后面） */
.tabs-bar::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  background: linear-gradient(to right, #ff9a9e, #fad0c4); /* 粉色渐变心形 */
  clip-path: polygon(50% 0%, 100% 35%, 85% 100%, 50% 75%, 15% 100%, 0% 35%);
  opacity: 0.3; /* 半透明效果 */
}

/* 标签默认样式 */
.tabs-bar .el-tab {
  font-weight: bold;
  font-size: 16px;
  color: #e91e63; /* 浪漫粉色字体 */
  padding: 10px 15px;
  border-radius: 12px; /* 圆角标签 */
  transition: all 0.3s ease;
}

/* 激活状态下的标签 */
.tabs-bar .el-tab.is-active {
  background: linear-gradient(to right, #ff9a9e, #fad0c4); /* 粉色渐变背景 */
  color: #fff; /* 激活状态文字为白色 */
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* 增加阴影提升立体感 */
  font-size: 18px; /* 激活状态字体稍大 */
}

/* 悬停效果 */
.tabs-bar .el-tab:hover {
  background: linear-gradient(to right, #fad0c4, #ff9a9e); /* 悬停时颜色反转 */
  color: #fff; /* 悬停状态文字为白色 */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影 */
}

/* 标签标题样式 */
.tabs-bar .el-tabs__header {
  background-color: transparent; /* 背景透明，与容器保持一致 */
  border: none; /* 去除边框 */
  padding: 10px;
}
/* 已完成清单项 */
.list-item-done {
  background-color: #e8f5e9; /* 浅绿色背景 */
  border: 1px solid #66bb6a; /* 绿色边框 */
  box-shadow: 0 4px 6px rgba(102, 187, 106, 0.3); /* 绿色阴影 */
  margin-bottom: 3px;
}

.list-item-done .item-title {
  color: #388e3c; /* 深绿色标题 */
}

.list-item-done .item-description {
  color: #66bb6a; /* 浅绿色描述 */
}

/* 未完成清单项 */
.list-item-pending {
  background-color: #f5f5f5; /* 浅灰色背景 */
  border: 1px solid #bdbdbd; /* 灰色边框 */
  box-shadow: 0 4px 6px rgba(189, 189, 189, 0.3); /* 灰色阴影 */
  margin-bottom: 3px;
}

.list-item-pending .item-title {
  color: #757575; /* 深灰色标题 */
}

.list-item-pending .item-description {
  color: #9e9e9e; /* 浅灰色描述 */
}
/* 动作按钮容器 */
.item-action {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 5px; */
  padding-top: 5px;
  width: 20%;
}

/* 打钩按钮 */
.el-button {
  background-color: #66bb6a;
  color: #fff;
}

.el-button:hover {
  background-color: #388e3c;
  color: #fff;
}
.check-icon {
  width: 60px; /* 限制图片宽度 */
  height: 60px; /* 限制图片高度 */
  cursor: pointer;
  object-fit: contain; /* 保持图片比例 */
  transition: transform 0.2s ease; /* 悬停动画 */
  margin-top: -10px; 
}
.item-right-information{
  width: 80%;
  height: 100%;
}
.item-right {
  width: 85%;
  display: flex;
  flex-direction: row; /* Arrange items horizontally */
  justify-content: space-between; /* Space them out */
  align-items: center; /* Vertically center align */
  margin-left: 10px;
}

/* Ensure item-right-information and item-action are aligned horizontally */
.item-right-information {
  flex: 1; /* Allow this part to take up remaining space */
}

.item-action {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
}

::v-deep .el-progress-bar__innerText {
  display: none !important;
}
</style>
