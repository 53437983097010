import axios from "axios";
import router from "@/router"; // 导入 Vue Router 实例
import { ElMessage } from "element-plus";

// 创建 Axios 实例
const apiClient = axios.create({
  // baseURL: "http://localhost:8080", // 替换为后端服务地址
  baseURL: "/api", // 替换为后端服务地址
  timeout: 150000, // 请求超时时间
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
apiClient.interceptors.request.use(
  (config) => {
    // 在请求发送之前，动态获取 JWT Token
    const token = localStorage.getItem('jwtToken');
    if (token) {
      // 如果 token 存在，设置 Authorization 头部
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
apiClient.interceptors.response.use(
  (response) => {
    // 统一处理成功响应
    const resData = response.data;

    // 检查自定义的响应状态码
    if (resData.code === 500 && resData.message.includes("jwt 已过期")) {
      // 清除 Token 并跳转到登录页面
      localStorage.removeItem("jwtToken");
      ElMessage.error("登录已过期，请重新登录！");
      router.push("/login");
      return Promise.reject(new Error("JWT 已过期")); // 返回错误，阻止后续代码执行
    }

    // 返回成功数据
    return resData;
  },
  (error) => {
    // 统一处理错误响应
    if (error.response) {
      // 判断是否是 401 错误
      if (error.response.status === 401) {
        localStorage.removeItem("jwtToken");
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);



export default apiClient;
