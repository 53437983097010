import apiClient from "./api";

const loveChecklistItemsApi = {
  /**
   * 分页查询恋爱清单项
   * @param {Object} params 查询参数
   * @returns {Promise}
   */
  queryByPage(params) {
    return apiClient.get("/loveChecklistItems", { params });
  },

  /**
   * 根据 ID 查询恋爱清单项
   * @param {Number} id 清单项 ID
   * @returns {Promise}
   */
  queryById(id) {
    return apiClient.get(`/loveChecklistItems/${id}`);
  },

  /**
   * 新增恋爱清单项
   * @param {Object} data 清单项数据
   * @returns {Promise}
   */
  addChecklistItem(data) {
    return apiClient.post("/loveChecklistItems", data);
  },

  /**
   * 编辑恋爱清单项
   * @param {Object} data 清单项数据
   * @returns {Promise}
   */
  editChecklistItem(data) {
    return apiClient.put("/loveChecklistItems", data);
  },

  /**
   * 删除恋爱清单项
   * @param {Number} id 清单项 ID
   * @returns {Promise}
   */
  deleteById(id) {
    return apiClient.delete("/loveChecklistItems", { params: { id } });
  },
};

export default loveChecklistItemsApi;
