<template>
  <div ref="sunlightContainer" class="sunlight-container"></div>
</template>

<script>
import * as THREE from "three";
import { Lensflare, LensflareElement } from "three/examples/jsm/objects/Lensflare";

export default {
  props: {
    lightColor: {
      type: String,
      default: "#FFD700", // 阳光颜色
    },
    lightIntensity: {
      type: Number,
      default: 1.5, // 光线强度
    },
    flareTextureSize: {
      type: Number,
      default: 256, // 镜头光晕纹理大小
    },
    flareColor: {
      type: String,
      default: "#FFAA00", // 镜头光晕颜色
    },
  },
  mounted() {
    this.initSunlightEffect();
  },
  methods: {
    initSunlightEffect() {
      const container = this.$refs.sunlightContainer;

      // 初始化场景、相机和渲染器
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 20;

      const renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 0); // 透明背景
      container.appendChild(renderer.domElement);

      // 添加光源
      const light = new THREE.SpotLight(this.lightColor, this.lightIntensity);
      light.position.set(30, 50, 50); // 右上角光源位置
      light.castShadow = true;
      scene.add(light);

      // 镜头光晕效果
      const textureLoader = new THREE.TextureLoader();
      const lensflareTexture = textureLoader.load(
        "https://threejs.org/examples/textures/lensflare/lensflare0.png" // 光晕纹理
      );

      const lensflare = new Lensflare();
      lensflare.addElement(new LensflareElement(lensflareTexture, this.flareTextureSize, 0, new THREE.Color(this.flareColor)));
      light.add(lensflare);

      // 动画
      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };

      animate();

      // 窗口大小调整
      window.addEventListener("resize", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
    },
  },
};
</script>

<style scoped>
.sunlight-container {
  position: fixed; /* 固定在页面顶部 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* 确保在页面顶层 */
  pointer-events: none; /* 不拦截鼠标事件 */
  background: transparent; /* 保持透明 */
}
</style>
