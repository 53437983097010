import { createRouter, createWebHashHistory } from "vue-router";
import ShowImages from "@/views/ShowImages.vue";
import File from "@/views/File.vue";
import ImageDetail from "@/views/ImageDetail.vue";
import Label from "@/views/Label.vue";
import Login from "@/views/Login.vue";
import Map from "@/views/Map.vue";
import Index from "@/views/Index.vue";
import Timeline from "@/views/Timeline.vue";
import Lovelist from "@/views/Lovelist.vue";
import RainEffect from "@/views/RainEffect.vue";
import { ElMessage } from "element-plus";
const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/show/:albumId",
    name: "ShowImages",
    component: ShowImages,
    props: true,
  },
  {
    path: "/file",
    name: "File",
    component: File,
  },
  {
    path: "/detail/:photoId",
    name: "ImageDetail",
    component: ImageDetail,
    props: true,
  },
  {
    path: "/label",
    name: "Label",
    component: Label,
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
  },
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: { requiresAuth: true }, // 添加需要认证的标识
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: Timeline,
    meta: { requiresAuth: true }, // 添加需要认证的标识
  },
  {
    path: "/lovelist",
    name: "Lovelist",
    component: Lovelist,
    meta: { requiresAuth: true }, // 添加需要认证的标识
  },
  {
    path: "/rainEffect",
    name: "RainEffect",
    component: RainEffect,
    meta: { requiresAuth: true }, // 添加需要认证的标识
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(atob(base64));
  } catch (error) {
    console.error("Token 解析失败:", error);
    return null;
  }
}

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("jwtToken");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      ElMessage.error("请先登录！"); // 未登录提示
      next("/login");
    } else {
      const decodedToken = parseJwt(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (!decodedToken || (decodedToken.exp && decodedToken.exp < currentTime)) {
        ElMessage.error("登录已过期，请重新登录！"); // Token 过期提示
        localStorage.removeItem("jwtToken");
        next("/login");
      } else {
        next(); // 允许访问
      }
    }
  } else {
    next(); // 不需要认证的路由
  }
});



export default router;
