<template>
  <div class="album-page">
    <!-- 顶部区域 -->
    <header class="header">
      <h1>我的相册</h1>
      <div class="action-bar">
        <el-input
          v-model="searchText"
          placeholder="搜索相册"
          clearable
          prefix-icon="el-icon-search"
          @input="filterAlbums"
          class="search-bar"
        />
        <el-button
          type="primary"
          icon="el-icon-folder-add"
          @click="createAlbum"
          class="create-button"
        >
          创建相册
        </el-button>
      </div>
    </header>

    <!-- 相册展示区域 -->
    <div class="album-list">
      <el-row gutter="20">
        <el-col
          :xs="8"
          :sm="8"
          :md="6"
          :lg="4"
          :xl="2"
          v-for="(album) in filteredAlbums"
          :key="album.albumId"
        >
          <div class="album-card" @click="goToAlbum(album.albumId)">
            <img :src="album.coverImage || defaultCover" alt="album cover" class="album-cover" />
            <div class="album-info">
              <h3 class="album-title">{{ album.name }}</h3>
              <p class="album-description">{{ album.description }}</p>
            </div>
            <div class="delete-button" @click.stop="deleteAlbum(album.albumId)">
              ×
            </div>

          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 创建相册弹窗 -->
    <el-dialog
      title="创建新相册"
      v-model="createDialogVisible" 
      width="88%"
    >
      <el-form :model="newAlbum">
        <el-form-item label="相册名称" :label-width="formLabelWidth">
          <el-input v-model="newAlbum.name" placeholder="请输入相册名称" />
        </el-form-item>
        <el-form-item label="相册描述" :label-width="formLabelWidth">
          <el-input v-model="newAlbum.description" placeholder="请输入相册描述" />
        </el-form-item>
        <el-form-item label="相册标题" :label-width="formLabelWidth">
          <el-input v-model="newAlbum.title" placeholder="请输入相册描述" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="createDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveAlbum">创建</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import albumsApi from "@/api/albumsApi"; // 引入封装的 API


export default {
  name: "AlbumPage",
  data() {
    return {
      searchText: "", // 搜索关键字
      albums: [], // 相册列表
      filteredAlbums: [], // 过滤后的相册列表
      createDialogVisible: false, // 创建相册弹窗显示控制
      newAlbum: {
        name: "",
        description: "",
      },
      formLabelWidth: "100px",
      defaultCover: require("@/assets/images/file_bg.png"), // 默认封面
    };
  },
  created() {
    this.getAlbums(); // 初始化加载相册列表
  },
  methods: {
    goToAlbum(albumId) {
      console.log("albumId:",albumId);
      this.$router.push({ name: "ShowImages",params: {albumId : albumId } });
    },
    // 获取相册列表
    async getAlbums() {
      try {
        const response = await albumsApi.queryByPage();
        if (response.code === 200) {
          this.albums = response.data || [];
          this.filteredAlbums = this.albums;
        } else {
          this.$message.error(response.message || "获取相册列表失败");
        }
      } catch (error) {
        console.error("获取相册列表失败：", error);
        this.$message.error("获取相册列表失败");
      }
    },

    // 搜索相册
    filterAlbums() {
      this.filteredAlbums = this.albums.filter((album) =>
        album.name.includes(this.searchText)
      );
    },

    // 创建相册
    createAlbum() {
      
      this.createDialogVisible = true;
      this.newAlbum = { name: "", description: "" };
    },

    // 保存新相册
    async saveAlbum() {
      if (!this.newAlbum.name) {
        this.$message.error("相册名称不能为空！");
        return;
      }
      try {
        const response = await albumsApi.addAlbum(this.newAlbum);
        if (response.code === 200) {
          this.$message.success("相册创建成功！");
          this.createDialogVisible = false;
          this.getAlbums(); // 刷新相册列表
        } else {
          this.$message.error(response.message || "创建相册失败");
        }
      } catch (error) {
        console.error("创建相册失败：", error);
        this.$message.error("创建相册失败");
      }
    },

    // 删除相册
    async deleteAlbum(albumId) {
      try {
        // 使用 Element Plus 的消息框确认对话框
        const confirm = await this.$confirm('确定要删除这个相册吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        // 如果用户点击了“确定”，继续删除操作
        if (confirm) {
          const response = await albumsApi.deleteById(albumId);
          if (response.code === 200) {
            this.$message.success("相册删除成功！");
            this.getAlbums(); // 刷新相册列表
          } else {
            this.$message.error(response.message || "删除相册失败");
          }
        }
      } catch (error) {
        console.error("删除相册失败：", error);
        this.$message.error("删除相册失败");
      }
    },

  },
};
</script>

<style scoped>
.album-page {
  font-family: "Arial", sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #ffebf2; /* 粉色背景色 */
  min-height: 100vh;
}

.cat-page {
  font-family: "Arial", sans-serif;
  padding: 20px;
  background: linear-gradient(135deg, #ffe4e1, #fffaf0);
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 7px 20px 7px;
}

.header h1 {
  font-size: 18px;
  color: #ff69b4;
  font-weight: bold;
}

.action-bar {
  display: flex;
  gap: 10px;
  max-width: 250px;
}

.search-bar {
  width: 300px;
}

.create-button {
  height: 40px;
  background-color: #ff6f91;
  border: none;
  font-weight: bold;
  max-width: 84px;
}

.create-button:hover {
  background-color: #ff477e;
}

.cat-list {
  padding-top: 20px;
}
.album-list{
  /* border: 1px solid black; */
  /* height: 100%; */
  width: 98%;
  margin: 0 1% 0 1%;
}
.cat-card {
  position: relative;
  cursor: pointer;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(255, 105, 180, 0.3);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  border: 2px solid #ffb6c1;
}

.cat-card:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(255, 105, 180, 0.4);
}

.cat-cover {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 2px solid #ffb6c1;
}

.cat-info {
  padding: 15px;
  text-align: center;
}

.cat-title {
  font-size: 18px;
  font-weight: bold;
  color: #ff69b4;
  margin-bottom: 5px;
}

.cat-description {
  font-size: 14px;
  color: #888;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  color: white;
  font-size: 14px; /* 调整字体大小以适配 'x' */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease, transform 0.3s ease;
}

.delete-button:hover {
  background: #ff4b4b;
  transform: scale(1.1); /* 鼠标悬停放大效果 */
}


.el-dialog {
  border-radius: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.el-button {
  border-radius: 20px;
}

.el-button--primary {
  background: #ff6f91;
  border: none;
  font-weight: bold;
}

.el-button--primary:hover {
  background: #ff477e;
}
.album-card {
  position: relative;
  cursor: pointer;
  background: linear-gradient(135deg, #fff0f5, #fff);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(255, 182, 193, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  border: 2px solid transparent;
  width: 100%; /* 自动适应列宽 */
  height: 210px; 
  margin-bottom: 5px;
}

.album-cover {
  width: 100%; /* 图片宽度占满父容器 */
  height: 73%; 
  object-fit: cover; /* 确保图片内容适应区域 */
  border-bottom: 2px solid #ffb6c1;
  transition: transform 0.3s ease;
}
.album-info {
  height: 27%;
  padding: 3px;
  text-align: center;
  background: linear-gradient(135deg, #ffe4e1, #fff0f5); /* 背景渐变 */
  border-top: 2px solid #ffb6c1; /* 顶部边框 */
  border-radius: 0 0 15px 15px; /* 圆角 */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* 内部阴影 */
}

.album-title {
  font-size: 11px;
  font-weight: bold;
  color: #ff69b4;
  margin-bottom: 5px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* 增加文字阴影 */
}

.album-description {
  font-size: 10px;
  color: #666;
  font-style: italic; /* 斜体 */
  line-height: 1.4;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); /* 增加文字阴影 */
}

</style>
