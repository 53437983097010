<template>
  <div ref="rainContainer" class="rain-container"></div>
</template>

<script>
import * as THREE from "three";

export default {
  props: {
    rainCount: {
      type: Number,
      default: 1000, // 雨滴数量
    },
    speed: {
      type: Number,
      default: 0.1, // 雨滴下落速度
    },
    length: {
      type: Number,
      default: 1, // 雨滴长度
    },
    color: {
      type: String,
      default: "#aaaaaa", // 雨滴颜色
    },
  },
  mounted() {
    this.initRainEffect();
  },
  methods: {
    initRainEffect() {
      const container = this.$refs.rainContainer;

      // 初始化场景、相机和渲染器
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 20;

      const renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 0); // 透明背景
      container.appendChild(renderer.domElement);

      // 创建雨滴几何体
      const rainGeometry = new THREE.BufferGeometry();
      const positions = [];
      const velocities = [];

      for (let i = 0; i < this.rainCount; i++) {
        const x = Math.random() * 40 - 20; // 雨滴的 x 坐标
        const y = Math.random() * 40 - 20; // 雨滴的 y 坐标
        const z = Math.random() * 40 - 20; // 雨滴的 z 坐标

        positions.push(x, y, z); // 起点
        positions.push(x, y - this.length, z); // 终点（短线段）

        velocities.push(Math.random() * this.speed + 0.05); // 下落速度
      }

      rainGeometry.setAttribute("position", new THREE.Float32BufferAttribute(positions, 3));
      rainGeometry.setAttribute("velocity", new THREE.Float32BufferAttribute(velocities, 1));

      // 着色器材质
      const rainMaterial = new THREE.LineBasicMaterial({
        color: new THREE.Color(this.color),
        linewidth: 1,
        transparent: true,
        opacity: 0.6,
      });

      const rain = new THREE.LineSegments(rainGeometry, rainMaterial);
      scene.add(rain);

      // 动画
      const animate = () => {
        requestAnimationFrame(animate);

        const positions = rainGeometry.attributes.position.array;
        const velocities = rainGeometry.attributes.velocity.array;

        for (let i = 0; i < positions.length; i += 6) {
          positions[i + 1] -= velocities[i / 6]; // 起点 y 坐标
          positions[i + 4] -= velocities[i / 6]; // 终点 y 坐标

          // 如果雨滴超出屏幕范围，重置到顶部
          if (positions[i + 1] < -20) {
            const x = Math.random() * 40 - 20;
            const z = Math.random() * 40 - 20;
            positions[i] = x; // 起点 x 坐标
            positions[i + 1] = 20; // 起点 y 坐标
            positions[i + 2] = z; // 起点 z 坐标

            positions[i + 3] = x; // 终点 x 坐标
            positions[i + 4] = 20 - this.length; // 终点 y 坐标
            positions[i + 5] = z; // 终点 z 坐标
          }
        }

        rainGeometry.attributes.position.needsUpdate = true; // 通知 Three.js 更新几何体

        renderer.render(scene, camera);
      };

      animate();

      // 窗口大小调整
      window.addEventListener("resize", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
    },
  },
};
</script>

<style scoped>
.rain-container {
  position: fixed; /* 固定在页面顶部 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* 确保在页面顶层 */
  pointer-events: none; /* 不拦截鼠标事件 */
  background: transparent; /* 保持透明 */
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.6); /* 阴天效果范围缩小 */
}

</style>
